import { Map, Marker, NavigationControl, FullscreenControl, Popup } from 'mapbox-gl';
import { template } from 'lodash';

export default function (Alpine) {
    Alpine.directive('map', map)

    function map(el, { expression }, { evaluate }) {
        const expressionData = evaluate(expression)

        let points = getValueFromExpressionData(expressionData, 'points');
        let center = getValueFromExpressionData(expressionData, 'center', true);
        let embed = !! getValueFromExpressionData(expressionData, 'embed', true);
        let fullscreenHref = getValueFromExpressionData(expressionData, 'fullscreenHref');
        let locale = getValueFromExpressionData(expressionData, 'locale');

        mapboxInstance(el, points, center, embed, fullscreenHref, locale);
    }
}

const markerCache = {};
let activeMarkers = {};

function mapboxInstance(el, points, center, embed, fullscreenHref, locale) {
    return new Promise((resolve, reject) => {
        let asEmbed = !! embed;
        let defaultLocation = [3.889029, 51.501721];
        let centerLocation = center !== null ? center : defaultLocation;

        let defaultZoom = asEmbed ? 7 : 11;
        let zoomLevel = center !== null ? 18 : defaultZoom;

        const markers = {
            'marker-cluster': '/img/map/cluster-marker.png',
            'marker-ecommit': '/img/map/ecommit-marker.png',
        }
        const map = new Map({
            container: el,
            style: 'mapbox://styles/distortedfusion/clox0rfqz010x01qo0ctd18hf',
            projection: 'mercator',
            center: centerLocation,
            zoom: zoomLevel,
            accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        })

        map.addControl(new NavigationControl(), 'bottom-right');

        if (asEmbed) {
            const fullscreenControl = new FullscreenControl();
            fullscreenControl._onClickFullscreen = () => window.location.href = fullscreenHref;

            map.addControl(fullscreenControl);
        }

        map.on('load', () => {
            let markerPromises = [];

            // Prepare a list of promises for all custom marker images...
            for (const [key, value] of Object.entries(markers)) {
                markerPromises.push(new Promise((resolve, reject) => {
                    map.loadImage(value, (error, image) => {
                        if (error) throw error;

                        map.addImage(key, image);

                        resolve(key);
                    });
                }));
            }

            // Once the markers have been loaded inject the points layer...
            Promise.all(markerPromises).then(() => {
                map.addSource('points', {
                    type: 'geojson',
                    data: points,
                    cluster: true,
                })

                map.addLayer({
                    id: 'unclustered-points',
                    type: 'circle',
                    source: 'points',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        // This adds a hidden circle behind the custom markers,
                        // used for the click handlers.
                        'circle-color': '#000',
                        'circle-opacity': 0,
                        'circle-radius': 24
                    }
                });

                map.addLayer({
                    id: 'clustered-points',
                    type: 'symbol',
                    source: 'points',
                    filter: ['has', 'point_count'],
                    layout: {
                        'icon-image': 'marker-cluster',
                        'icon-size': 0.5,
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': [
                            'Arial Unicode MS Bold'
                        ],
                        'text-offset': [0.7, 0.1],
                        'text-anchor': 'top'
                    },
                    paint: {
                        "text-color": "#ffffff",
                    }
                });

                // Inspect cluster on click, this zooms in onto the cluster...
                map.on('click', 'clustered-points', (e) => {
                    const features = map.queryRenderedFeatures(e.point, {
                        layers: ['clustered-points']
                    });
                    const clusterId = features[0].properties.cluster_id;

                    map.getSource('points').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return;

                        map.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom
                        });
                    });
                });

                // Handle custom markers...
                map.on('render', () => {
                    if (map.isSourceLoaded('points')) {
                        updateMarkers(map);
                    }
                });

                // Handle popups...
                map.on('click', 'unclustered-points', (e) => {
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const properties = e.features[0].properties;
                    const hrefs = JSON.parse(e.features[0].properties.hrefs);

                    properties.href = hrefs[locale];

                    map.flyTo({
                        center: coordinates,
                        offset: [0, 175],
                    });

                    new Popup({
                        className: 'font-sans w-80',
                        anchor: 'bottom',
                        maxWidth: 320,
                        closeButton: false,
                        focusAfterOpen: false,
                        offset: {
                            // Marker size: roof(94 / 4) + 5
                            'bottom': [0, -29],
                        }
                    }).setLngLat(coordinates).setHTML(template(
                        document.getElementById('map-popup').innerHTML
                    )(properties)).addTo(map);
                });

                // Handle cursor styling...
                map.on('mouseenter', ['clustered-points'], () => {
                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', ['clustered-points'], () => {
                    map.getCanvas().style.cursor = '';
                });
            }).then(() => {
                resolve(map);
            })
        })
    })
}

function updateMarkers(map) {
    const visibleMarkers = {};

    const features = map.querySourceFeatures('points');

    for (const feature of features) {
        const properties = feature.properties;
        const id = properties.id;

        // Use the `clustered-points` layer for clusters.
        if (properties.cluster) {
            continue;
        }

        let marker = markerInstance(feature);

        visibleMarkers[id] = marker;

        if (! activeMarkers[id]) {
            marker.addTo(map);
        }
    }

    removeInactiveMarkers(visibleMarkers);
}

function removeInactiveMarkers(visibleMarkers)
{
    for (const id in activeMarkers) {
        if (! visibleMarkers[id]) {
            activeMarkers[id].remove();
        }
    }

    activeMarkers = visibleMarkers;
}

function markerInstance(feature)
{
    const properties = feature.properties;
    const coords = feature.geometry.coordinates;
    const id = properties.id;

    if (markerCache[id]) {
        return markerCache[id];
    }

    const el = createHtmlMarker(properties);

    markerCache[id] = new Marker({
        element: el
    }).setLngLat(coords);

    return markerCache[id];
}

function createHtmlMarker(properties) {
    const el = document.createElement('div');

    el.innerHTML = template(
        document.getElementById('map-marker').innerHTML
    )(properties);

    return el.firstChild;
}

function getValueFromExpressionData(data, key, optional = false) {
    if (! data.hasOwnProperty(key) && optional) {
        return null;
    }

    if (! data.hasOwnProperty(key)) {
        throwError(key);
    }

    const rawValue = data[key];

    if ((rawValue === undefined || rawValue === null) && ! optional) {
        throwError(key);
    }

    return rawValue;
}

function throwError(key) {
    throw new Error('Missing ['+key+'] expression for x-map directive, property must be provided.')
}
